<section id="four" class="about">
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="center" >
        <div fxFlex="100" fxFlex.gt-sm="30" class="about-img" fxLayoutAlign="center">
          <img  [src]="getHost.getHttpsImage() + 'imageEntreprise/image6.jpg'" alt="">
        </div>
        <div fxFlex="100" fxFlex.gt-sm="70" class="about-text">
          <h4>Umex est une plateforme sécurisée de location de voitures et d’utilitaires basée sur la consommation collaborative. Le concept propose un usage plus simple et plus flexible de la voiture avec la possibilité pour l’utilisateur ou entreprise locataire de louer un véhicule en quelques clics,à chaque instant, partout en Cote d'ivoire.</h4>
          <div class="separator"></div>
          <p>Nous faisons ainsi bénéficier à nos locataires d’une solution bon marché, 30 à 40% moins chère que chez les loueurs traditionnels, pour se déplacer simplement et quand il le souhaite, le temps d’un week-end, d’un déménagement, d’un déplacement professionnel ou d’une semaine de vacances. Chacun y trouve son compte, c’est un service gagnant-gagnant !</p>
          <p style="text-align: center;"><a mat-raised-button color="primary">En savoir plus ? <mat-icon>trending_flat</mat-icon></a></p>
        </div>
      </div>
    </div>
  </section>