import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VerifResponsiveService {
  isSmallScreen$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) { }

  confirmResponsive() {
    this.isSmallScreen$ = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.TabletPortrait]) // Points de rupture pour petits écrans
    .pipe(map((result) => result.matches));

    return this.isSmallScreen$;
  }

}
