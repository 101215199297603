import { ErrorComponent } from './pages/error/error.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { LockScreenComponent } from "./pages/lock-screen/lock-screen.component";
import { TestradiogroupComponent } from "./pages/testradiogroup/testradiogroup.component";
import { PaymentRedirectionComponent } from './pages/payment-redirection/payment-redirection.component';
import { PaymentResolver } from 'src/app/resolvers/payment.resolver';
import { NotationCarComponent } from './compte-utilisateur/mes-locations/notation-car/notation-car.component';
import { PaymentRedirectionExtensionComponent } from './pages/payment-redirection-extension/payment-redirection-extension.component';
import { PaymentExtensionResolver } from './resolvers/payment-extension.resolver';
import { ModelVehiculesComponent } from './pages/model-vehicules/model-vehicules.component';


//import { LoginGoogleComponent } from './pages/social-login/social-login.component';


const routes: Routes = [
  { 
    path: '', 
    component: PagesComponent, children: [
        //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
        { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
        { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
        { path: 'documentation', loadChildren: () => import('./pages/documentation/documentation.module').then(m => m.DocumentationModule) },
        { path: 'ConditionAssurance', loadChildren: () => import('./pages/condition-assurance/ConditionAssurance.module').then(m => m.ConditionAssuranceModule) },
        { path: 'ConditionGeneral', loadChildren: () => import('./pages/conditions-general/conditionGeneral.module').then(m => m.ConditionGeneralModule) },
        { path: 'ContratLocation', loadChildren: () => import('./pages/contrat-location/contratLocation.module').then(m => m.ContratLocationModule) },
        { path: 'Fonctionnement', loadChildren: () => import('./pages/fonctionnement/fonctionnement.module').then(m => m.FonctionnementModule) },
        { path: 'FonctionnementLocataire', loadChildren: () => import('./pages/fonctionnement-locataire/fonctionnementLocataire.module').then(m => m.FonctionnementLocataireModule) },

        { path: 'assurance', loadChildren: () => import('./pages/assurance/assurances.module').then(m => m.AssuranceModule) },
        { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
        { path: 'vehicules-disponibles', loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule) },
        { path: 'nos-selections', loadChildren: () => import('./pages/menu2/menu2.module').then(m => m.Menu2Module) },
        { path: 'chefs', loadChildren: () => import('./pages/chefs/chefs.module').then(m => m.ChefsModule) },
        { path: 'reservation', loadChildren: () => import('./pages/reservation/reservation.module').then(m => m.ReservationModule) },
        { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
        { path: 'switch', loadChildren: () => import('./pages/switch/switch.module').then(m => m.SwitchModule) },
        { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
        { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
        { path: 'location-car', loadChildren: () => import('./pages/location-car/location-car.module').then(m => m.LocationCarModule) },
        { path: 'reset', loadChildren: () => import('./pages/login/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
        { path: 'newpass', loadChildren: () => import('./pages/login/newpassword/newpassword.module').then(m => m.NewpasswordModule) },
	      { path: 'verif', loadChildren: () => import('./pages/login/verifcode/verifcode.module').then(m => m.VerifdModule) },
        { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
        { path: 'validation-telephone', loadChildren: () => import('./pages/login/valider-telephone/valider-telephone.module').then(m => m.ValidationTelephoneModule) },
        { path: 'verifcode-telephone', loadChildren: () => import('./pages/login/verifcode-telephone/verifcode-telephone.module').then(m => m.VerifcodeTelephonedModule) },
        { path: 'validation-email', loadChildren: () => import('./pages/login/validation-email/validation-email.module').then(m => m.ValidationEmailModule) },
        {path:'entreprise', loadChildren:() => import('./pages/entreprise/entreprise.module').then(m=> m.EntrepriseModule)},
        {path:'model-voitures/:model',component: ModelVehiculesComponent},
        { path: 'create-utilisateurs', loadChildren: () => import('./pages/create-utilisateurs/create-utilisateurs.module').then(m => m.CreateUtilisateursModule) },

       // { path: 'social-login', loadChildren: () => import('./pages/social-login/social-login.module').then(m => m.SocialLoginModule) },
        //{ path: 'login-google', loadChildren: () => import('./pages/login-google/login-google.module').then(m => m.LoginGoogleModule) },
       // { path: 'login-facebook', loadChildren: () => import('./pages/login-facebook/login-facebook.module').then(m => m.LoginFacebookModule) },

      // {path: 'login-google', component : LoginGoogleComponent},

        { path: 'registerPro', loadChildren: () => import('./pages/registerPro/registerPro.module').then(m => m.RegisterProModule) },
        { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
        { path: 'terms-conditions', loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
        { path: 'search-car', loadChildren: () => import('./pages/search-car/search-car.module').then(m => m.SearchCarModule) },
        { path: 'location-car', loadChildren: () => import('./pages/location-car/location-car.module').then(m => m.LocationCarModule) },
        { path: 'latest-location-car', loadChildren: () => import('./pages/latest-location-car/latest-location-car.module').then(m => m.LatestLocationCarModule) },
        { path: 'payment-redirection/:id/:paymentMethod', component: PaymentRedirectionComponent, resolve: {payment: PaymentResolver} },
        { path: 'payment-extension/:id/:paymentMethod', component: PaymentRedirectionExtensionComponent, resolve: {payment: PaymentExtensionResolver} },
        {path: 'notation/:id', component : NotationCarComponent},
    ],
  },
  { path: 'admin', loadChildren: () => import('./super-admin/super-admin.module').then(m => m.SuperAdminModule) },
  
  { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
  { path: 'radioTest', component:TestradiogroupComponent },
  { path: 'compte-utilisateur', loadChildren: () => import('./compte-utilisateur/compte-utilisateur.module').then(m => m.CompteUtilisateurModule) },
  { path: 'lock-screen', component: LockScreenComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: NotFoundComponent },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      initialNavigation: "enabledBlocking", // for one load page, without reload
      relativeLinkResolution: "legacy",
      // useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
